import * as React from 'react';

import { IHeaderData } from '../header.data';
import { IHeaderProps } from '../header.props.autogenerated';


export class HeaderMyAccountPicker extends React.PureComponent<IHeaderProps<IHeaderData>> {

    private readonly _cookieName: string = '_msdyn365___mayaccountsite_';
    private readonly _cookieExpirationInSeconds: number = 5_184_000;
    public constructor(props: IHeaderProps<IHeaderData>){
        super(props);
        this._handleChange = this._handleChange.bind(this);
        this._resetSiteOptionsState = this._resetSiteOptionsState.bind(this);
    }
    private _handleChange(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        e.preventDefault();
        if (e.currentTarget.href && e.currentTarget.href.length > 0 && window) {
            if (this.props.context.request.cookies.isConsentGiven()) {
                const cookieConfig = { maxAge: this._cookieExpirationInSeconds };
                this.props.context.request.cookies.set(this._cookieName, e.currentTarget.href, cookieConfig);
            }
            window.location.assign(e.currentTarget.href);
        }
    }

    private _resetSiteOptionsState(): void {
        this.setState({
            expandSiteOptions: false
        });
    }

    public render(): JSX.Element | undefined {
        const {
            context: {
                request: {
                    user
                }
            }
        } = this.props;

        return ( <div><div className="ms-site-picker desktop-view" id="" data-m-t="site-picker"><button className="ms-site-picker__button" aria-label="Site" aria-expanded="false">My Account<span className="ms-site-picker__span"></span></button></div>
        <a
            key={0}
            className='ms-site-picker__list__item__link'
            tabIndex={0}
            href={user && user.signInUrl}
            aria-label="Sign In"
            onClick={this._handleChange}
            role='menuitem'
        >
            <span className='ms-site-picker__list__item__text'>
            Sign In
            </span>
        </a>
        <a
        key={1}
        className='ms-site-picker__list__item__link'
        tabIndex={1}
        href='/business-sign-up'
        aria-label="Request A Business Account"
        onClick={this._handleChange}
        role='menuitem'
    >
        <span className='ms-site-picker__list__item__text'>
        Request An Account
        </span>
    </a></div>);
    }
}

export default HeaderMyAccountPicker;