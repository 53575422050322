import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import React from 'react';

export interface IHeaderErrorNotificationPopup {
    className: string;
    modalHeader: string;
    modalBodyText: string;
    modalOpen: boolean | undefined;
    onClose(): void;
}

export const HeaderErrorNotificationPopup = (dialogProps: IHeaderErrorNotificationPopup) => {
    const {
        className,
        modalOpen
    } = dialogProps;


    const close: () => void = () => {
        dialogProps.onClose();
    };

    return(
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <span>{dialogProps.modalHeader}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`} style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: dialogProps.modalBodyText }}
                />
            </ModalBody>
            <ModalFooter  className={`${className}__dialog__footer`}>
                <Button   className={`${className}__dialog__continue-shopping`}   title={'Ok'}  onClick={close}>
                    {'Ok'}
                </Button>
            </ModalFooter>

        </Modal>
    );
};